export interface Sign {
    id: string;
    name: string;
    icon: string;
    source: string;
    height?: number;
    width?: number;
    top?: number;
    left?: number;
    zIndex?: number;
}

/*
Calles, intersecciones y Glorietas // streetSigns
Mobiliario Urbano // objectSings
Vegetación // vegetalSings
Señales Verticales // verticalSings
Señales Horizontales // horizontalSings
Otras Señales y Objetos // otherSings
Vehículos // carsSings
Personas // peopleSings
Animales // animalSings
Líneas y Formas // arrowSings

*/

export const vegetalSings:Array<Sign> = [
        {
          id: "arbol",
          name: "Árbol",
          icon: "images/nature/arbol.png",
          source: "images/nature/arbol.svg"
        },
        {
          "id": "arboles_serie",
          "name": "Árboles en Serie",
          "icon": "images/nature/arboles_serie.png",
          "source": "images/nature/arboles_serie.svg"
        },
        {
          "id": "arbol_1",
          "name": "Árbol 1",
          "icon": "images/nature/arbol_1.png",
          "source": "images/nature/arbol_1.svg"
        },
        {
          "id": "arbol_2",
          "name": "Árbol 2",
          "icon": "images/nature/arbol_2.png",
          "source": "images/nature/arbol_2.svg"
        },
        {
          "id": "arbusto",
          "name": "Arbusto",
          "icon": "images/nature/arbusto.png",
          "source": "images/nature/arbusto.svg"
        },
        {
          "id": "arbusto_suelo",
          "name": "Arbusto Suelo",
          "icon": "images/nature/arbusto_suelo.png",
          "source": "images/nature/arbusto_suelo.svg"
        },
        {
          "id": "cesped",
          "name": "Césped",
          "icon": "images/nature/cesped.png",
          "source": "images/nature/cesped.svg"
        },
        {
          "id": "cesped_2",
          "name": "Césped 2",
          "icon": "images/nature/cesped_2.png",
          "source": "images/nature/cesped_2.svg"
        },
        {
          "id": "cesped_3",
          "name": "Césped 3",
          "icon": "images/nature/cesped_3.png",
          "source": "images/nature/cesped_3.svg"
        }        
];

export const trafficHSigns: Array<Sign>=[
    {"id": "aparcamiento_oblicuo", "name": "Aparcamiento Oblicuo", "icon": "images/traffic-signs-h/aparcamiento_oblicuo.png", "source": "images/traffic-signs-h/aparcamiento_oblicuo.svg"},
    {"id": "aparcamiento_vertical", "name": "Aparcamiento Vertical", "icon": "images/traffic-signs-h/aparcamiento_vertical.png", "source": "images/traffic-signs-h/aparcamiento_vertical.svg"},
    {"id": "bus", "name": "Bus", "icon": "images/traffic-signs-h/bus.png", "source": "images/traffic-signs-h/bus.svg"},
    {"id": "ceda", "name": "Ceda el Paso", "icon": "images/traffic-signs-h/ceda.png", "source": "images/traffic-signs-h/ceda.svg"},
    {"id": "discontinua", "name": "Línea Discontinua", "icon": "images/traffic-signs-h/discontinua.png", "source": "images/traffic-signs-h/discontinua.svg"},
    {"id": "discontinua_obras", "name": "Línea Discontinua Obras", "icon": "images/traffic-signs-h/discontinua_obras.png", "source": "images/traffic-signs-h/discontinua_obras.svg"},
    {"id": "flecha_derecha", "name": "Flecha Derecha", "icon": "images/traffic-signs-h/flecha_derecha.png", "source": "images/traffic-signs-h/flecha_derecha.svg"},
    {"id": "flecha_derecha_2", "name": "Flecha Derecha 2", "icon": "images/traffic-signs-h/flecha_derecha_2.png", "source": "images/traffic-signs-h/flecha_derecha_2.svg"},
    {"id": "flecha_derecha_3", "name": "Flecha Derecha 3", "icon": "images/traffic-signs-h/flecha_derecha_3.png", "source": "images/traffic-signs-h/flecha_derecha_3.svg"},
    {"id": "flecha_izquierda", "name": "Flecha Izquierda", "icon": "images/traffic-signs-h/flecha_izquierda.png", "source": "images/traffic-signs-h/flecha_izquierda.svg"},
    {"id": "flecha_izquierda_2", "name": "Flecha Izquierda 2", "icon": "images/traffic-signs-h/flecha_izquierda_2.png", "source": "images/traffic-signs-h/flecha_izquierda_2.svg"},
    {"id": "flecha_izquierda_3", "name": "Flecha Izquierda 3", "icon": "images/traffic-signs-h/flecha_izquierda_3.png", "source": "images/traffic-signs-h/flecha_izquierda_3.svg"},
    {"id": "flecha_izquierda_derecha", "name": "Flecha Izquierda-Derecha", "icon": "images/traffic-signs-h/flecha_izquierda_derecha.png", "source": "images/traffic-signs-h/flecha_izquierda_derecha.svg"},
    {"id": "flecha_recto", "name": "Flecha Recto", "icon": "images/traffic-signs-h/flecha_recto.png", "source": "images/traffic-signs-h/flecha_recto.svg"},
    {"id": "flecha_recto_derecha", "name": "Flecha Recto-Derecha", "icon": "images/traffic-signs-h/flecha_recto_derecha.png", "source": "images/traffic-signs-h/flecha_recto_derecha.svg"},
    {"id": "flecha_recto_izquierda", "name": "Flecha Recto-Izquierda", "icon": "images/traffic-signs-h/flecha_recto_izquierda.png", "source": "images/traffic-signs-h/flecha_recto_izquierda.svg"},
    {"id": "grid_amarillo", "name": "Grid Amarillo", "icon": "images/traffic-signs-h/grid_amarillo.png", "source": "images/traffic-signs-h/grid_amarillo.svg"},
    {"id": "linea", "name": "Línea", "icon": "images/traffic-signs-h/linea.png", "source": "images/traffic-signs-h/linea.svg"},
    {"id": "linea_obras", "name": "Línea Obras", "icon": "images/traffic-signs-h/linea_obras.png", "source": "images/traffic-signs-h/linea_obras.svg"},
    {"id": "paso_peatones", "name": "Paso de Peatones", "icon": "images/traffic-signs-h/paso_peatones.png", "source": "images/traffic-signs-h/paso_peatones.svg"},
    {"id": "paso_peatones_2", "name": "Paso de Peatones 2", "icon": "images/traffic-signs-h/paso_peatones_2.png", "source": "images/traffic-signs-h/paso_peatones_2.svg"},
    {"id": "reservado_bus", "name": "Reservado Bus", "icon": "images/traffic-signs-h/reservado_bus.png", "source": "images/traffic-signs-h/reservado_bus.svg"},
    {"id": "reservado_taxi", "name": "Reservado Taxi", "icon": "images/traffic-signs-h/reservado_taxi.png", "source": "images/traffic-signs-h/reservado_taxi.svg"},
    {"id": "reserva_plaza", "name": "Reserva Plaza", "icon": "images/traffic-signs-h/reserva_plaza.png", "source": "images/traffic-signs-h/reserva_plaza.svg"},
    {"id": "stop", "name": "Stop", "icon": "images/traffic-signs-h/stop.png", "source": "images/traffic-signs-h/stop.svg"},
    {"id": "taxi", "name": "Taxi", "icon": "images/traffic-signs-h/taxi.png", "source": "images/traffic-signs-h/taxi.svg"},
    {"id": "triangulo_peaton_concavo", "name": "Triángulo Peatón Cóncavo", "icon": "images/traffic-signs-h/triangulo_peaton_concavo.png", "source": "images/traffic-signs-h/triangulo_peaton_concavo.svg"},
    {"id": "triangulo_peaton_convexo", "name": "Triángulo Peatón Convexo", "icon": "images/traffic-signs-h/triangulo_peaton_convexo.png", "source": "images/traffic-signs-h/triangulo_peaton_convexo.svg"},
    {"id": "triangulo_peatpm", "name": "Triángulo Peatón PM", "icon": "images/traffic-signs-h/triangulo_peatpm.png", "source": "images/traffic-signs-h/triangulo_peatpm.svg"}
  ];

  export const otherTrafficSigns:Array<Sign> = [
    {"id": "cinta", "name": "Cinta", "icon": "images/traffic-others/cinta.png", "source": "images/traffic-others/cinta.svg"},
    {"id": "cinta_policia_local", "name": "Cinta Policía Local", "icon": "images/traffic-others/cinta_policia_local.png", "source": "images/traffic-others/cinta_policia_local.svg"},
    {"id": "cono", "name": "Cono", "icon": "images/traffic-others/cono.png", "source": "images/traffic-others/cono.svg"},
    {"id": "cuba", "name": "Cuba", "icon": "images/traffic-others/cuba.png", "source": "images/traffic-others/cuba.svg"},
    {"id": "desvio", "name": "Desvío", "icon": "images/traffic-others/desvio.png", "source": "images/traffic-others/desvio.svg"},
    {"id": "luz", "name": "Luz", "icon": "images/traffic-others/luz.png", "source": "images/traffic-others/luz.svg"},
    {"id": "malla", "name": "Malla", "icon": "images/traffic-others/malla.png", "source": "images/traffic-others/malla.svg"},
    {"id": "otros_1", "name": "Otros 1", "icon": "images/traffic-others/otros_1.png", "source": "images/traffic-others/otros_1.svg"},
    {"id": "otros_2", "name": "Otros 2", "icon": "images/traffic-others/otros_2.png", "source": "images/traffic-others/otros_2.svg"},
    {"id": "otros_3", "name": "Otros 3", "icon": "images/traffic-others/otros_3.png", "source": "images/traffic-others/otros_3.svg"},
    {"id": "palo_azul", "name": "Palo Azul", "icon": "images/traffic-others/palo_azul.png", "source": "images/traffic-others/palo_azul.svg"},
    {"id": "palo_negro", "name": "Palo Negro", "icon": "images/traffic-others/palo_negro.png", "source": "images/traffic-others/palo_negro.svg"},
    {"id": "palo_rojo", "name": "Palo Rojo", "icon": "images/traffic-others/palo_rojo.png", "source": "images/traffic-others/palo_rojo.svg"},
    {"id": "palo_verde", "name": "Palo Verde", "icon": "images/traffic-others/palo_verde.png", "source": "images/traffic-others/palo_verde.svg"},
    {"id": "quitamiedos", "name": "Quitamiedos", "icon": "images/traffic-others/quitamiedos.png", "source": "images/traffic-others/quitamiedos.svg"},
    {"id": "valla", "name": "Valla", "icon": "images/traffic-others/valla.png", "source": "images/traffic-others/valla.svg"},
    {"id": "valla_estacionar", "name": "Valla Prohibido Estacionar", "icon": "images/traffic-others/valla_estacionar.png", "source": "images/traffic-others/valla_estacionar.svg"},
    {"id": "valla_parar_estacionar", "name": "Valla Prohibido Parar y Estacionar", "icon": "images/traffic-others/valla_parar_estacionar.png", "source": "images/traffic-others/valla_parar_estacionar.svg"},
    {"id": "valla_paso", "name": "Valla Paso", "icon": "images/traffic-others/valla_paso.png", "source": "images/traffic-others/valla_paso.svg"},
    {"id": "valla_sentido_derecha", "name": "Valla Sentido Derecha", "icon": "images/traffic-others/valla_sentido_derecha.png", "source": "images/traffic-others/valla_sentido_derecha.svg"},
    {"id": "valla_sentido_izquierda", "name": "Valla Sentido Izquierda", "icon": "images/traffic-others/valla_sentido_izquierda.png", "source": "images/traffic-others/valla_sentido_izquierda.svg"}
  ];

export const trafficSigns: Array<Sign> = [
    {"id": "advertencia_ninos", "name": "Advertencia Niños", "icon": "images/signals/advertencia_ninos.png", "source": "images/signals/advertencia_ninos.svg"},
    {"id": "advertencia_obras", "name": "Advertencia Obras", "icon": "images/signals/advertencia_obras.png", "source": "images/signals/advertencia_obras.svg"},
    {"id": "advertencia_resalto", "name": "Advertencia Resalto", "icon": "images/signals/advertencia_resalto.png", "source": "images/signals/advertencia_resalto.svg"},
    {"id": "advertencia_rotonda", "name": "Advertencia Rotonda", "icon": "images/signals/advertencia_rotonda.png", "source": "images/signals/advertencia_rotonda.svg"},
    {"id": "advertencia_semaforo", "name": "Advertencia Semáforo", "icon": "images/signals/advertencia_semaforo.png", "source": "images/signals/advertencia_semaforo.svg"},
    {"id": "ceda", "name": "Ceda el Paso", "icon": "images/signals/ceda.png", "source": "images/signals/ceda.svg"},
    {"id": "fin_prohibido_acusticas", "name": "Fin Prohibición Acústicas", "icon": "images/signals/fin_prohibido_acusticas.png", "source": "images/signals/fin_prohibido_acusticas.svg"},
    {"id": "fin_prohibido_adelantar", "name": "Fin Prohibición Adelantar", "icon": "images/signals/fin_prohibido_adelantar.png", "source": "images/signals/fin_prohibido_adelantar.svg"},
    {"id": "fin_restricciones", "name": "Fin Restricciones", "icon": "images/signals/fin_restricciones.png", "source": "images/signals/fin_restricciones.svg"},
    {"id": "fin_velocidad_20", "name": "Fin Velocidad 20", "icon": "images/signals/fin_velocidad_20.png", "source": "images/signals/fin_velocidad_20.svg"},
    {"id": "informacion_calle_sin_salida", "name": "Calle Sin Salida", "icon": "images/signals/informacion_calle_sin_salida.png", "source": "images/signals/informacion_calle_sin_salida.svg"},
    {"id": "prohibido_girar_derecha", "name": "Prohibido Girar Derecha", "icon": "images/signals/prohibido_girar_derecha.png", "source": "images/signals/prohibido_girar_derecha.svg"},
    {"id": "prohibido_girar_izquierda", "name": "Prohibido Girar Izquierda", "icon": "images/signals/prohibido_girar_izquierda.png", "source": "images/signals/prohibido_girar_izquierda.svg"},
    {"id": "prohibido_parar_estacionar", "name": "Prohibido Parar y Estacionar", "icon": "images/signals/prohibido_parar_estacionar.png", "source": "images/signals/prohibido_parar_estacionar.svg"},
    {"id": "sentido_prohibido", "name": "Sentido Prohibido", "icon": "images/signals/sentido_prohibido.png", "source": "images/signals/sentido_prohibido.svg"},
    {"id": "stop", "name": "Stop", "icon": "images/signals/stop.png", "source": "images/signals/stop.svg"},
    {"id": "velocidad_30", "name": "Velocidad 30", "icon": "images/signals/velocidad_30.png", "source": "images/signals/velocidad_30.svg"},
    {"id": "velocidad_50", "name": "Velocidad 50", "icon": "images/signals/velocidad_50.png", "source": "images/signals/velocidad_50.svg"},
    {"id": "semaforo_rojo", "name": "Semáforo Rojo", "icon": "images/signals/semaforo_rojo.png", "source": "images/signals/semaforo_rojo.svg"},
    {"id": "semaforo_verde", "name": "Semáforo Verde", "icon": "images/signals/semaforo_verde.png", "source": "images/signals/semaforo_verde.svg"}
  ];

export const streetSigns: Array<Sign> = [
    {
        id: "horizontal",
        name: "Calle Horizontal",
        icon: "images/street/horizontals.svg",
        source: "images/street/horizontals.svg",
        height: 100,
        width: 100,
        top: 0,
        left: 0,
        zIndex: 1000
    },
    {
        id: "vertical",
        name: "Calle Vertical",
        icon: "images/street/verticals.png",
        source: "images/street/verticals.png",
        height: 100,
        width: 100,
        top: 0,
        left: 0,
        zIndex: 1000
    },
    {
        id: "T-down",
        name: "Intersección en T Abajo",
        icon: "images/street/T-downs.png",
        source: "images/street/T-downs.png",
        height: 200,
        width: 300,
        top: 0,
        left: 0,
        zIndex: 1000
    },
    {
        id: "T-up",
        name: "Intersección en T Arriba",
        icon: "images/street/T-ups.png",
        source: "images/street/T-ups.png",
        height: 200,
        width: 300,
        top: 0,
        left: 0,
        zIndex: 1000,
    },
    {
        id: "T-left",
        name: "Intersección en T Izquierda",
        icon: "images/street/T-lefts.png",
        source: "images/street/T-lefts.png",
        height: 300,
        width: 200,
        top: 0,
        left: 0,
        zIndex: 1000,
    },
    {
        id: "T-right",
        name: "Intersección en T Derecha",
        icon: "images/street/T-rights.png",
        source: "images/street/T-rights.png",
        height: 300,
        width: 200,
        top: 0,
        left: 0,
        zIndex: 1000,
    },
    {
        id: "intersection",
        name: "Intersección",
        icon: "images/street/intersections.png",
        source: "images/street/intersections.png",
        height: 300,
        width: 300,
        top: 0,
        left: 0,
        zIndex: 1000,
    },
    {
        id: "round-intersection",
        name: "Glorieta",
        icon: "images/street/round-intersections.png",
        source: "images/street/round-intersections.png",
        height: 300,
        width: 300,
        top: 0,
        left: 0,
        zIndex: 1000,
    },
    {
          "id": "acera",
          "name": "Acera",
          "icon": "images/street/acera.png",
          "source": "images/street/acera.svg",
          "zIndex": 1000
        },
        {
          "id": "calzada",
          "name": "Calzada",
          "icon": "images/street/calzada.png",
          "source": "images/street/calzada.svg",
          "zIndex": 1000
        },
        {
          "id": "edificacion",
          "name": "Edificación",
          "icon": "images/street/edificacion.png",
          "source": "images/street/edificacion.svg",
          "zIndex": 1000
        },
        {
          "id": "glorieta_azul",
          "name": "Glorieta Azul",
          "icon": "images/street/glorieta_azul.png",
          "source": "images/street/glorieta_azul.svg",
          "zIndex": 1000
        },
        {
          "id": "glorieta_marron",
          "name": "Glorieta Marrón",
          "icon": "images/street/glorieta_marron.png",
          "source": "images/street/glorieta_marron.svg",
          "zIndex": 1000
        },
        {
          "id": "glorieta_verde",
          "name": "Glorieta Verde",
          "icon": "images/street/glorieta_verde.png",
          "source": "images/street/glorieta_verde.svg",
          "zIndex": 1000
        },
        {
          "id": "solar",
          "name": "Solar",
          "icon": "images/street/solar.png",
          "source": "images/street/solar.svg",
          "zIndex": 1000
        },
        {
          "id": "triangulo_concavo_asfalto",
          "name": "Triángulo Cóncavo Asfalto",
          "icon": "images/street/triangulo_concavo_asfalto.png",
          "source": "images/street/triangulo_concavo_asfalto.svg",
          "zIndex": 1000
        },
        {
          "id": "triangulo_concavo_cesped",
          "name": "Triángulo Cóncavo Césped",
          "icon": "images/street/triangulo_concavo_cesped.png",
          "source": "images/street/triangulo_concavo_cesped.svg",
          "zIndex": 1000
        },
        {
          "id": "triangulo_concavo_tierra",
          "name": "Triángulo Cóncavo Tierra",
          "icon": "images/street/triangulo_concavo_tierra.png",
          "source": "images/street/triangulo_concavo_tierra.svg",
          "zIndex": 1000
        },
        {
          "id": "triangulo_convexo_asfalto",
          "name": "Triángulo Convexo Asfalto",
          "icon": "images/street/triangulo_convexo_asfalto.png",
          "source": "images/street/triangulo_convexo_asfalto.svg",
          "zIndex": 1000
        },
        {
          "id": "triangulo_convexo_cesped",
          "name": "Triángulo Convexo Césped",
          "icon": "images/street/triangulo_convexo_cesped.png",
          "source": "images/street/triangulo_convexo_cesped.svg",
          "zIndex": 1000
        },
        {
          "id": "triangulo_convexo_tierra",
          "name": "Triángulo Convexo Tierra",
          "icon": "images/street/triangulo_convexo_tierra.png",
          "source": "images/street/triangulo_convexo_tierra.svg",
          "zIndex": 1000
        },
        {
          "id": "triangulo_recto_asfalto",
          "name": "Triángulo Recto Asfalto",
          "icon": "images/street/triangulo_recto_asfalto.png",
          "source": "images/street/triangulo_recto_asfalto.svg",
          "zIndex": 1000
        },
        {
          "id": "triangulo_recto_cesped",
          "name": "Triángulo Recto Césped",
          "icon": "images/street/triangulo_recto_cesped.png",
          "source": "images/street/triangulo_recto_cesped.svg",
          "zIndex": 1000
        },
        {
          "id": "triangulo_recto_tierra",
          "name": "Triángulo Recto Tierra",
          "icon": "images/street/triangulo_recto_tierra.png",
          "source": "images/street/triangulo_recto_tierra.svg",
          "zIndex": 1000
        }      
];

export const carsSings: Array<Sign> = [
    {
        id: "coche",
        name: "Coche",
        icon: "images/cars/coche.png",
        source: "images/cars/coche.svg",
        height: 300,
        width: 300,
        top: 0,
        left: 0,
    },
    {
        id: "coche2",
        name: "coche2",
        icon: "images/cars/coche2.png",
        source: "images/cars/coche2.svg",
        height: 300,
        width: 300,
        top: 0,
        left: 0,
    },
    {
        id: "coche3",
        name: "coche3",
        icon: "images/cars/coche3.png",
        source: "images/cars/coche3.svg",
        height: 300,
        width: 300,
        top: 0,
        left: 0,
    },
    {
        id: "bus",
        name: "Autobús",
        icon: "images/cars/bus.png",
        source: "images/cars/bus.svg",
        height: 150,
        width: 150,
        top: 0,
        left: 0,
    },
    {
        id: "sidecar",
        name: "sidecar",
        icon: "images/cars/sidecar.png",
        source: "images/cars/sidecar.svg",
        height: 150,
        width: 150,
        top: 0,
        left: 0,
    },
    {
        id: "moto",
        name: "moto",
        icon: "images/cars/moto.png",
        source: "images/cars/moto.svg",
        height: 150,
        width: 150,
        top: 0,
        left: 0,
    },
    {
        id: "moto2",
        name: "moto2",
        icon: "images/cars/moto2.png",
        source: "images/cars/moto2.svg",
        height: 150,
        width: 150,
        top: 0,
        left: 0,
    },
        {"id": "ambulancia", "name": "Ambulancia", "icon": "images/cars/ambulancia.png", "source": "images/cars/ambulancia.svg"},
        {"id": "autobus", "name": "Autobús", "icon": "images/cars/autobus.png", "source": "images/cars/autobus.svg"},
        {"id": "barredora", "name": "Barredora", "icon": "images/cars/barredora.png", "source": "images/cars/barredora.svg"},
        {"id": "bicicleta_adulto", "name": "Bicicleta Adulto", "icon": "images/cars/bicicleta_adulto.png", "source": "images/cars/bicicleta_adulto.svg"},
        {"id": "bicicleta_infantil", "name": "Bicicleta Infantil", "icon": "images/cars/bicicleta_infantil.png", "source": "images/cars/bicicleta_infantil.svg"},
        {"id": "cabeza_tractora", "name": "Cabeza Tractora", "icon": "images/cars/cabeza_tractora.png", "source": "images/cars/cabeza_tractora.svg"},
        {"id": "camion", "name": "Camión", "icon": "images/cars/camion.png", "source": "images/cars/camion.svg"},
        {"id": "camion_basura", "name": "Camión Basura", "icon": "images/cars/camion_basura.png", "source": "images/cars/camion_basura.svg"},
        {"id": "camion_caja", "name": "Camión Caja", "icon": "images/cars/camion_caja.png", "source": "images/cars/camion_caja.svg"},
        {"id": "carretilla_elevadora", "name": "Carretilla Elevadora", "icon": "images/cars/carretilla_elevadora.png", "source": "images/cars/carretilla_elevadora.svg"},
        {"id": "ciclomotor", "name": "Ciclomotor", "icon": "images/cars/ciclomotor.png", "source": "images/cars/ciclomotor.svg"},
        {"id": "cisterna", "name": "Cisterna", "icon": "images/cars/cisterna.png", "source": "images/cars/cisterna.svg"},
        {"id": "dumper", "name": "Dumper", "icon": "images/cars/dumper.png", "source": "images/cars/dumper.svg"},
        {"id": "furgon", "name": "Furgón", "icon": "images/cars/furgon.png", "source": "images/cars/furgon.svg"},
        {"id": "furgoneta", "name": "Furgoneta", "icon": "images/cars/furgoneta.png", "source": "images/cars/furgoneta.svg"},
        {"id": "grua_excavadora", "name": "Grúa Excavadora", "icon": "images/cars/grua_excavadora.png", "source": "images/cars/grua_excavadora.svg"},
        {"id": "grua_telescopica", "name": "Grúa Telescópica", "icon": "images/cars/grua_telescopica.png", "source": "images/cars/grua_telescopica.svg"},
        {"id": "guardia_civil_trafico", "name": "Guardia Civil Tráfico", "icon": "images/cars/guardia_civil_trafico.png", "source": "images/cars/guardia_civil_trafico.svg"},
        {"id": "microbus", "name": "Microbús", "icon": "images/cars/microbus.png", "source": "images/cars/microbus.svg"},
        {"id": "monovolumen", "name": "Monovolumen", "icon": "images/cars/monovolumen.png", "source": "images/cars/monovolumen.svg", "height": 150,"width": 150,},
        {"id": "motocarro", "name": "Motocarro", "icon": "images/cars/motocarro.png", "source": "images/cars/motocarro.svg"},
        {"id": "motocicleta", "name": "Motocicleta", "icon": "images/cars/motocicleta.png", "source": "images/cars/motocicleta.svg"},
        {"id": "mulita_mecanica", "name": "Mulita Mecánica", "icon": "images/cars/mulita_mecanica.png", "source": "images/cars/mulita_mecanica.svg"},
        {"id": "policia_local", "name": "Policía Local", "icon": "images/cars/policia_local.png", "source": "images/cars/policia_local.svg"},
        {"id": "policia_nacional", "name": "Policía Nacional", "icon": "images/cars/policia_nacional.png", "source": "images/cars/policia_nacional.svg"},
        {"id": "remolque", "name": "Remolque", "icon": "images/cars/remolque.png", "source": "images/cars/remolque.svg"},
        {"id": "remolque_pesado", "name": "Remolque Pesado", "icon": "images/cars/remolque_pesado.png", "source": "images/cars/remolque_pesado.svg"},
        {"id": "sidecar", "name": "Sidecar", "icon": "images/cars/sidecar.png", "source": "images/cars/sidecar.svg"},
        {"id": "todoterreno", "name": "Todoterreno", "icon": "images/cars/todoterreno.png", "source": "images/cars/todoterreno.svg"},
        {"id": "tractor", "name": "Tractor", "icon": "images/cars/tractor.png", "source": "images/cars/tractor.svg"},
        {"id": "tractor_con_remolque", "name": "Tractor con Remolque", "icon": "images/cars/tractor_con_remolque.png", "source": "images/cars/tractor_con_remolque.svg"},
        {"id": "trailer", "name": "Tráiler", "icon": "images/cars/trailer.png", "source": "images/cars/trailer.svg"},
        {"id": "turismo", "name": "Turismo", "icon": "images/cars/turismo.png", "source": "images/cars/turismo.svg"},
        {"id": "vehiculo_traccion_animal", "name": "Vehículo Tracción Animal", "icon": "images/cars/vehiculo_traccion_animal.png", "source": "images/cars/vehiculo_traccion_animal.svg"},
        {
            id: "camion",
            name: "camion",
            icon: "images/cars/camion.png",
            source: "images/cars/camion.svg",
            height: 150,
            width: 150,
            top: 0,
            left: 0,
        },
        {
            id: "especial",
            name: "especial",
            icon: "images/cars/especial.png",
            source: "images/cars/especial.svg",
            height: 150,
            width: 150,
            top: 0,
            left: 0,
        },
        {
            id: "especial_2",
            name: "carruaje",
            icon: "images/cars/especial_2.png",
            source: "images/cars/especial_2.svg",
            height: 150,
            width: 150,
            top: 0,
            left: 0,
        },
        {
            id: "especial_3",
            name: "especial_3",
            icon: "images/cars/especial_3.png",
            source: "images/cars/especial_3.svg",
            height: 150,
            width: 150,
            top: 0,
            left: 0,
        },
        {
            id: "especial_4",
            name: "especial_4",
            icon: "images/cars/especial_4.png",
            source: "images/cars/especial_4.svg",
            height: 150,
            width: 150,
            top: 0,
            left: 0,
        },
        {
            id: "especial_5",
            name: "especial_5",
            icon: "images/cars/especial_5.png",
            source: "images/cars/especial_5.svg",
            height: 150,
            width: 150,
            top: 0,
            left: 0,
        },
        {
            id: "especial_6",
            name: "especial_6",
            icon: "images/cars/especial_6.png",
            source: "images/cars/especial_6.svg",
            height: 150,
            width: 150,
            top: 0,
            left: 0,
        },
        {
            id: "especial_7",
            name: "especial_7",
            icon: "images/cars/especial_7.png",
            source: "images/cars/especial_7.svg",
            height: 150,
            width: 150,
            top: 0,
            left: 0,
        },
        {
            id: "especial_8",
            name: "especial_8",
            icon: "images/cars/especial_8.png",
            source: "images/cars/especial_8.svg",
            height: 150,
            width: 150,
            top: 0,
            left: 0,
        },
        {
            id: "especial_9",
            name: "especial_9",
            icon: "images/cars/especial_9.png",
            source: "images/cars/especial_9.svg",
            height: 150,
            width: 150,
            top: 0,
            left: 0,
        },
        {
            id: "especial_10",
            name: "especial_10",
            icon: "images/cars/especial_10.png",
            source: "images/cars/especial_10.svg",
            height: 150,
            width: 150,
            top: 0,
            left: 0,
        }
];


export const peopleSings: Array<Sign> = [
    {"id": "hombre_moreno_herido", "name": "Hombre Moreno Herido", "icon": "images/people/hombre_moreno_herido.png", "source": "images/people/hombre_moreno_herido.svg"},
    {"id": "hombre_moreno_peaton", "name": "Hombre Moreno Peatón", "icon": "images/people/hombre_moreno_peaton.png", "source": "images/people/hombre_moreno_peaton.svg"},
    {"id": "hombre_rubio_herido", "name": "Hombre Rubio Herido", "icon": "images/people/hombre_rubio_herido.png", "source": "images/people/hombre_rubio_herido.svg"},
    {"id": "hombre_rubio_peaton", "name": "Hombre Rubio Peatón", "icon": "images/people/hombre_rubio_peaton.png", "source": "images/people/hombre_rubio_peaton.svg"},
    {"id": "mujer_castana_peaton", "name": "Mujer Castaña Peatón", "icon": "images/people/mujer_castana_peaton.png", "source": "images/people/mujer_castana_peaton.svg"},
    {"id": "mujer_morena_herida", "name": "Mujer Morena Herida", "icon": "images/people/mujer_morena_herida.png", "source": "images/people/mujer_morena_herida.svg"},
    {"id": "mujer_morena_peaton", "name": "Mujer Morena Peatón", "icon": "images/people/mujer_morena_peaton.png", "source": "images/people/mujer_morena_peaton.svg"},
    {"id": "mujer_rubia_herida", "name": "Mujer Rubia Herida", "icon": "images/people/mujer_rubia_herida.png", "source": "images/people/mujer_rubia_herida.svg"}
  ];

export const objectSings: Array<Sign> = [
    {"id": "banco", "name": "Banco", "icon": "images/furniture/banco.png", "source": "images/furniture/banco.svg"},
    {"id": "contenedor_basura", "name": "Contenedor Basura", "icon": "images/furniture/contenedor_basura.png", "source": "images/furniture/contenedor_basura.svg"},
    {"id": "contenedor_papel", "name": "Contenedor Papel", "icon": "images/furniture/contenedor_papel.png", "source": "images/furniture/contenedor_papel.svg"},
    {"id": "contenedor_plastico", "name": "Contenedor Plástico", "icon": "images/furniture/contenedor_plastico.png", "source": "images/furniture/contenedor_plastico.svg"},
    {"id": "contenedor_vidrio", "name": "Contenedor Vidrio", "icon": "images/furniture/contenedor_vidrio.png", "source": "images/furniture/contenedor_vidrio.svg"},
    {"id": "contenedor_vista_cielo", "name": "Contenedor Vista Cielo", "icon": "images/furniture/contenedor_vista_cielo.png", "source": "images/furniture/contenedor_vista_cielo.svg"},
    {"id": "farola", "name": "Farola", "icon": "images/furniture/farola.png", "source": "images/furniture/farola.svg"},
    {"id": "farola_1_cielo", "name": "Farola 1 Vista Cielo", "icon": "images/furniture/farola_1_cielo.png", "source": "images/furniture/farola_1_cielo.svg"},
    {"id": "farola_2_cielo", "name": "Farola 2 Vista Cielo", "icon": "images/furniture/farola_2_cielo.png", "source": "images/furniture/farola_2_cielo.svg"},
    {"id": "farola_3_cielo", "name": "Farola 3 Vista Cielo", "icon": "images/furniture/farola_3_cielo.png", "source": "images/furniture/farola_3_cielo.svg"},
    {"id": "farola_4_cielo", "name": "Farola 4 Vista Cielo", "icon": "images/furniture/farola_4_cielo.png", "source": "images/furniture/farola_4_cielo.svg"},
    {"id": "marquesina", "name": "Marquesina", "icon": "images/furniture/marquesina.png", "source": "images/furniture/marquesina.svg"},
    {"id": "papelera", "name": "Papelera", "icon": "images/furniture/papelera.png", "source": "images/furniture/papelera.svg"},
    {"id": "parada_bus", "name": "Parada de Bus", "icon": "images/furniture/parada_bus.png", "source": "images/furniture/parada_bus.svg"}
  ]
  ;


export const arrowSigns: Array<Sign> = [
    {
      id: 'arrow1',
      icon: '/images/arrows/arrow1.svg',
      source: '/images/arrows/arrow1.svg',
      name: 'Flecha 1',
      top: 0,
      left: 0,
      height: 20,
      width: 100,
    },
    {
      id: 'arrow2',
      icon: '/images/arrows/arrow2.svg',
      source: '/images/arrows/arrow2.svg',
      name: 'Flecha 2',
      top: 0,
      left: 0,
      height: 20,
      width: 100,
    },
    {
      id: 'arrow3',
      icon: '/images/arrows/arrow3.svg',
      source: '/images/arrows/arrow3.svg',
      name: 'Flecha 3',
      top: 0,
      left: 0,
      height: 20,
      width: 100,
    },
    {
        id: 'arrow4',
        icon: '/images/arrows/arrow4.svg',
        source: '/images/arrows/arrow4.svg',
        name: 'Flecha 4',
        top: 0,
        left: 0,
        height: 20,
        width: 100,
    },
    {"id": "aceite", "name": "Aceite", "icon": "images/arrows/aceite.png", "source": "images/arrows/aceite.svg"},
    {"id": "frenada_curva", "name": "Frenada en Curva", "icon": "images/arrows/frenada_curva.png", "source": "images/arrows/frenada_curva.svg"},
    {"id": "frenada_irregular", "name": "Frenada Irregular", "icon": "images/arrows/frenada_irregular.png", "source": "images/arrows/frenada_irregular.svg"},
    {"id": "frenada_recta", "name": "Frenada en Recta", "icon": "images/arrows/frenada_recta.png", "source": "images/arrows/frenada_recta.svg"},
    {"id": "impacto_1", "name": "Impacto 1", "icon": "images/arrows/impacto_1.png", "source": "images/arrows/impacto_1.svg"},
    {"id": "impacto_2", "name": "Impacto 2", "icon": "images/arrows/impacto_2.png", "source": "images/arrows/impacto_2.svg"},
    {"id": "restos_vehiculos", "name": "Restos de Vehículos", "icon": "images/arrows/restos_vehiculos.png", "source": "images/arrows/restos_vehiculos.svg"},
    {"id": "sangre", "name": "Sangre", "icon": "images/arrows/sangre.png", "source": "images/arrows/sangre.svg"}
];


export const animalsSigns: Array<Sign> = [
    {
      id: 'caballo',
      icon: '/images/animals/caballo.png',
      source: '/images/animals/caballo.svg',
      name: 'Caballo',
      top: 0,
      left: 0,
      height: 20,
      width: 100,
    },
    {
      id: 'cerdo',
      icon: '/images/animals/cerdo.png',
      source: '/images/animals/cerdo.svg',
      name: 'Cerdo',
      top: 0,
      left: 0,
      height: 20,
      width: 100,
    },
    {
      id: 'gatos',
      icon: '/images/animals/gatos.png',
      source: '/images/animals/gatos.svg',
      name: 'Gatos',
      top: 0,
      left: 0,
      height: 20,
      width: 100,
    },
    {
        id: 'perro',
        icon: '/images/animals/perro.png',
        source: '/images/animals/perro.svg',
        name: 'Perro',
        top: 0,
        left: 0,
        height: 20,
        width: 100,
    },
    {
        id: 'vaca',
        icon: '/images/animals/vaca.png',
        source: '/images/animals/vaca.svg',
        name: 'Vaca',
        top: 0,
        left: 0,
        height: 20,
        width: 100,
    },
];


