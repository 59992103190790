import { useEffect, useRef, useState, ChangeEvent, useCallback } from "react";
import html3pdf from 'html3pdf';
import { useReactToPrint } from "react-to-print";
import { StatefulTargetBoard } from "./Board";
import { SidebarOptions } from "./SidebarOptions";
import { useNavigate } from 'react-router-dom';
import { Header } from './Header';
import { MAX_COLUMN, MAX_ROW, WIDTH_AND_HEIGHT } from "../constants";
import { Element as CustomElement } from "./Element/types";
import { useProject } from '../context/ProjectContext';
import { useReport } from '../context/ReportContext';
import { useLocation } from 'react-router-dom';
import { PDFDocument } from 'pdf-lib';
import { useServer } from '../context/ServerContext';
import { fetchWithAuth } from '../lib/utils';

const createPrintContainer = async (componentRef: React.RefObject<HTMLDivElement>, boardItems: CustomElement[]) => {
  const container = document.createElement('div');
  const firstPage = document.createElement('div');
  firstPage.className = 'first-page';
  const secondPage = document.createElement('div');
  secondPage.className = 'second-page';
  const header = document.createElement('div');
  const board = document.createElement('div');
  const description = document.createElement('div');
  const legend = document.createElement('div');

  const headerElement = componentRef.current?.querySelector('.header');
  const boardElement = componentRef.current?.querySelector('.board');
  const descriptionElement = document.querySelector('.accident-description');

  const styles = `
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
    @page:first {
      size: A4 landscape;
    }
    @page {
      size: A4 portrait;
    }
    div {
      box-sizing: border-box;
      font-family: 'Open Sans', sans-serif;
    }
    .print-container {
      width: 85%;
      margin: auto;
      padding: 20px;
    }
    .print-container .board {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      width: 90%;
      margin-top: 40px;
    }
    .header img {
      width: 130px !important;
    }
    .header #policia {
      width: 280px !important;
    }
    .accident-description {
      margin: 20px 0;
      height: auto !important;
      overflow: visible !important;
    }
    .accident-description h1 {
      text-transform: uppercase !important;
      margin-bottom: 10px !important;
    }
    .accident-description p {
      text-align: justify !important;
    }
    .board {
      overflow: visible !important;
    }
    .Element-container {
      cursor: move;
      width: 150px;
      height: 150px;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .Element-container img {
      width: 100px !important;
      max-height:90px !important;
    }
    .board-footer {
      display: none;
    }
    .moveable-control-box {
      display: none !important;
    }
    .moveable-control-box .moveable-line {
      display: none !important;
    }
    .moveable-control-box .moveable-control {
      display: none !important;
    }
  `;

  const styleSheet = document.createElement("style");
  styleSheet.type = "text/css";
  styleSheet.innerText = styles;
  container.appendChild(styleSheet);

  if (headerElement) {
    const headerClone = headerElement.cloneNode(true) as HTMLElement;
    headerClone.style.display = 'flex';
    header.innerHTML = headerClone.outerHTML;

    if (boardElement) {
      board.innerHTML = boardElement.outerHTML;
    }

    const svgElement = board.querySelector('svg');
    if (svgElement) {
      svgElement.removeAttribute('width');
      svgElement.removeAttribute('height');
      const viewBoxWidth = MAX_COLUMN * WIDTH_AND_HEIGHT;
      const viewBoxHeight = MAX_ROW * WIDTH_AND_HEIGHT;
      svgElement.setAttribute('viewBox', `0 0 ${viewBoxWidth} ${viewBoxHeight}`);

      // Convert images in the SVG to data URLs
      const images = Array.from(svgElement.querySelectorAll('image'));
      for (const image of images) {
        const href = image.getAttribute('href');
        if (href) {
          const response = await fetch(href);
          const blob = await response.blob();
          const reader = new FileReader();
          reader.onloadend = () => {
            image.setAttribute('href', reader.result as string);
          };
          reader.readAsDataURL(blob);
        }
      }
    }

    firstPage.appendChild(header.cloneNode(true));
    firstPage.appendChild(board);
    firstPage.appendChild(document.createElement('div')).style.pageBreakAfter = 'always';
    const spacerDiv = document.createElement('div');
    spacerDiv.style.height = '20px';
    secondPage.appendChild(spacerDiv);
    secondPage.appendChild(header.cloneNode(true));
    if (descriptionElement) {
      const originalDisplay = (descriptionElement as HTMLElement).style.display;
      (descriptionElement as HTMLElement).style.display = 'block';

      description.innerHTML = descriptionElement.outerHTML;
      description.style.pageBreakBefore = 'avoid';
      description.style.pageBreakInside = 'avoid';
      secondPage.appendChild(description);

      (descriptionElement as HTMLElement).style.display = originalDisplay;
    }

    const legendContainer = document.createElement('div');
    legendContainer.style.pageBreakInside = 'avoid';
    legendContainer.style.pageBreakBefore = 'avoid';

    const legendTitle = document.createElement('h1');
    legendTitle.className = 'font-bold mt-4 mb-4';
    legendTitle.textContent = 'LEYENDA DEL CROQUIS';
    legendContainer.appendChild(legendTitle);

    legend.style.display = 'grid';
    legend.style.gridTemplateColumns = 'repeat(2, 1fr)';
    legend.style.gap = '20px';

    for (const item of boardItems) {
      if (item.type === "arrow") continue;
      
      const legendItem = document.createElement('div');
      legendItem.style.display = 'flex';
      legendItem.style.justifyContent = 'space-between';
      legendItem.style.alignItems = 'center';
      legendItem.style.gap = '10px';

      const elementContainer1 = document.createElement('div');
      elementContainer1.className = 'Element-container';
      elementContainer1.style.flex = '0 0 150px';

      const img = new Image();
      img.src = item.source;
      img.style.display = 'block';
      img.style.width = '100%';
      img.style.height = 'auto';

      await new Promise((resolve) => {
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          if (ctx) {
            ctx.drawImage(img, 0, 0);
            elementContainer1.innerHTML = `<img src="${canvas.toDataURL()}" alt="${item.name}" style="display: block; width: 100%; height: auto;"/>`;
          }
          resolve(null);
        };
      });

      const textContainer = document.createElement('div');
      textContainer.style.display = 'flex';
      textContainer.style.flexDirection = 'column';
      textContainer.style.alignItems = 'flex-start';
      textContainer.style.flex = '1';

      const name1 = document.createElement('p');
      name1.textContent = item.name.toUpperCase();
      name1.style.margin = '0';
      name1.style.fontSize = '1rem';
      name1.style.fontWeight = 'bold';

      const description1 = document.createElement('p');
      description1.textContent = item.description || '';
      description1.style.margin = '0';
      description1.style.fontSize = '0.875rem';
      description1.style.wordWrap = 'break-word';

      textContainer.appendChild(name1);
      textContainer.appendChild(description1);

      legendItem.appendChild(elementContainer1);
      legendItem.appendChild(textContainer);

      legend.appendChild(legendItem);
    }
    legendContainer.appendChild(legend);
    secondPage.appendChild(legendContainer);
  }

  container.appendChild(firstPage);
  container.appendChild(secondPage);
  container.classList.add('print-container');

  return container;
};

export const MainLayout = () => {
  const { projectName, setProjectName } = useProject();
  const { reportData, setReportData } = useReport();
  const [boardItems, setBoardItems] = useState<CustomElement[]>([]);
  const [image, setImage] = useState<string | null>(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [printContainer, setPrintContainer] = useState<HTMLDivElement | null>(null);

  const componentRef = useRef<HTMLDivElement | null>(null);
  const imageUploadRef = useRef<HTMLInputElement | null>(null);

  const { setServidor, setId } = useServer();
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean);
  const servidor_id = pathSegments[pathSegments.length - 1];
  const [servidor, id] = servidor_id.split('_');

  useEffect(() => {
    if (servidor && id) {
      setServidor(servidor);
      setId(id);
      handleLoadReport();
    }
  }, [servidor, id, setServidor, setId]);

  const handleLoadReport = useCallback(async () => {
    try {
      const baseUrl = process.env.NODE_ENV === 'production' && servidor !== 'desarrollo'
      ? `https://${servidor}.swpolicial.com`
      : ''; // Empty string to use the proxy in development
      
      const response = await fetch(`${baseUrl}/api/croquizador/${id}`, {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
      });

      if (!response.ok) {
        setReportData(null);
        return;
      }

      const result = JSON.parse(await response.json());

      const data = {
        coatOfArms: result.escudo,
        townHallName: result.ayuntamiento,
        registrationNumber: result.registro,
        inspector: "",
        accidentAddress: result.direccion,
        accidentDescription: result.descripcion,
        reportNumber: result.id
      };
      setReportData(data);

      const projectData = result.contenido ? JSON.parse(result.contenido) : null;
      if (projectData) {
        const updatedItems = await Promise.all(projectData.boardItems.map(async (item: CustomElement) => {
          if (item.isSvg && item.selectedColor) {
            const response = await fetch(item.originalSource);
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.includes("image/svg+xml")) {
              let svgText = await response.text();
              svgText = svgText.replace(/\.st0{fill:[^;]*;/g, `.st0{fill:${item.selectedColor};`);
              const svgBlob = new Blob([svgText], { type: 'image/svg+xml' });
              const newHref = URL.createObjectURL(svgBlob);
              return { ...item, source: newHref };
            }
          }
          return item;
        }));

        setBoardItems(updatedItems);
        setImage(projectData.backgroundImage);
        if (projectData.reportData) {
          setReportData(projectData.reportData);
        }
      } else {
        console.log('No project data found for the given report number.');
      }
    } catch (error) {
      console.error('No se ha podido cargar el expediente.');
      setReportData(null);
    }
      console.log("false");
  }, [setReportData, setBoardItems, setImage]);

  const updatePrintContainer = async () => {
    if (componentRef.current) {
      const container = await createPrintContainer(componentRef, boardItems);
      setPrintContainer(container);
    }
  };

  useEffect(() => {
    updatePrintContainer();
  }, [componentRef, boardItems]);

  const handlePrint = useReactToPrint({
    content: () => printContainer,
  });

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const imageURL = URL.createObjectURL(file);
      setImage(imageURL);
      localStorage.removeItem('capturedMapImage');
    }
  };

  const navigate = useNavigate();

  const handleNavigateToMap = () => {
    navigate('/map');
  };

  useEffect(() => {
    const capturedImage = localStorage.getItem('capturedMapImage');
    if (capturedImage) {
      setImage(capturedImage);
      localStorage.removeItem('capturedMapImage');
    }
  }, []);

  const SavePDF = useReactToPrint({
    content: () => printContainer,
    documentTitle: `${projectName}.pdf`,
    copyStyles: true,
    print: async (printIframe: HTMLIFrameElement) => {
      const document = printIframe.contentDocument;
      if (document) {
        const html = document.getElementsByTagName('html')[0];
  
        // Split the HTML content into two parts
        const firstPageHtml = html.cloneNode(true) as HTMLElement;
        const secondPageHtml = html.cloneNode(true) as HTMLElement;
  
        // Remove the content for the second page from the first page HTML
        const secondPageContent = firstPageHtml.querySelector('.second-page');
        if (secondPageContent) {
          secondPageContent.remove();
        }
  
        // Remove the content for the first page from the second page HTML
        const firstPageContent = secondPageHtml.querySelector('.first-page');
        if (firstPageContent) {
          firstPageContent.remove();
        }
  
        // Generate the first page (landscape)
        const firstPagePdf = await html3pdf().set({
          jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' },
          html2canvas: { scale: 2 },
          image: { type: 'jpeg', quality: 0.98 },
        }).from(firstPageHtml).outputPdf('arraybuffer');
  
        // Generate the second page (portrait)
        const secondPagePdf = await html3pdf().set({
          jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
          html2canvas: { scale: 2 },
          image: { type: 'jpeg', quality: 0.98 },
        }).from(secondPageHtml).outputPdf('arraybuffer');
  
        const firstPdfDoc = await PDFDocument.load(firstPagePdf);
        const secondPdfDoc = await PDFDocument.load(secondPagePdf);
  
        const mergedPdfDoc = await PDFDocument.create();
  
        const [firstPage] = await mergedPdfDoc.copyPages(firstPdfDoc, [0]);
        const [secondPage] = await mergedPdfDoc.copyPages(secondPdfDoc, [0]);
  
        mergedPdfDoc.addPage(firstPage);
        mergedPdfDoc.addPage(secondPage);
  
        const mergedPdfBytes = await mergedPdfDoc.save();
  
        const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
        const formData = new FormData();
        formData.append('fichero', blob, `${reportData?.reportNumber}.pdf`);
  
        try {
          const baseUrl = process.env.NODE_ENV === 'production'
          ? `https://${servidor}.swpolicial.com`
          : ''; // Empty string to use the proxy in development

          const response = await fetch(`${baseUrl}/api/croquizador/pdf/${id}`, {
            method: 'POST',
            body: formData
          });
  
          if (response.ok) {
            console.log("PDF guardado con éxito en el servidor.");
          } else {
            console.error('Error al guardar el PDF en el servidor.');
          }
        } catch (error) {
          console.error('Error en la solicitud para guardar el PDF:', error);
        }
      }
    }
  });


  const handleSave = async () => {
    await updatePrintContainer();

    if (!reportData?.reportNumber) {
      console.error("Report number is required to save the project.");
      return;
    }

    const projectData = {
      projectName: reportData.reportNumber,
      boardItems,
      backgroundImage: image,
      reportData,
    };

    const baseUrl = process.env.NODE_ENV === 'production'
    ? `https://${servidor}.swpolicial.com`
    : ''; // Empty string to use the proxy in development

    const response = await fetch(`${baseUrl}/api/croquizador/${id}`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ contenido: projectData })
    });

    if (response.ok) {
      console.log("Croquis guardado con éxito");
    } else {
      console.error('Failed to save project data to the server.');
    }

    SavePDF();
  };

  return (
    <div ref={componentRef}>
      <Header />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
          height: "90vh",
        }}
      >
        <div
          style={{
            flex: 30,
            maxWidth: 300,
            height: "100vh",
            padding: 10,
            display: "flex",
            justifyContent: "center",
            overflow: "scroll",
          }}
        >
          <SidebarOptions
            handlePrint={handlePrint}
            handleImageUpload={handleImageUpload}
            handleNavigateToMap={handleNavigateToMap}
            imageUploadRef={imageUploadRef}
            handleSave={handleSave}
            setShowSuccessMessage={setShowSuccessMessage}
          />
        </div>
        <div
          style={{
            flex: 70,
            padding: 10,
            height: "100vh",
            overflow: "scroll",
          }}
        >
          <div className="board">
            <StatefulTargetBoard key={image} image={image} boardItems={boardItems} setBoardItems={setBoardItems} />
          </div>
        </div>
      </div>
      {showSuccessMessage && (
        <div className="fixed top-4 right-4 bg-green-500 text-white px-4 py-2 rounded shadow-lg">
          Proyecto guardado con éxito!
        </div>
      )}
    </div>
  );
};