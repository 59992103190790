import { Sign, streetSigns, trafficSigns, carsSings,peopleSings,objectSings, arrowSigns, animalsSigns,vegetalSings,trafficHSigns,otherTrafficSigns} from "../data";
import { Element } from "./Element";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/accordion";
import { ChangeEvent, RefObject } from "react";
import { useReport } from "../context/ReportContext";
import AccidentDescription from './AccidentDescription';

interface SidebarOptionsProps {
  handlePrint: () => void;
  handleImageUpload: (event: ChangeEvent<HTMLInputElement>) => void;
  handleNavigateToMap: () => void;
  imageUploadRef: RefObject<HTMLInputElement>;
  handleSave: () => void;
  setShowSuccessMessage: (show: boolean) => void;
}

const SidebarGroup = ({
  title,
  options,
}: {
  title: string;
  options: Array<Sign>;
}) => {
  return (
    <AccordionItem value={title}>
      <AccordionTrigger>{title}</AccordionTrigger>
      <AccordionContent>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))",
            gap: 10,
            maxHeight: 300,
            overflowY: "scroll",
          }}
        >
          {options.map((sign) => {
            return (
              <div
                key={sign.id}
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Element
                  id=""
                  icon={sign.icon}
                  source={sign.source}
                  top={sign.top ?? 0}
                  left={sign.left ?? 0}
                  height={sign.height ?? 100}
                  width={sign.width ?? 100}
                  zIndex={sign.zIndex ?? 2000}
                  name={sign.name}
                  moveItem={() => {}}
                  originalSource={sign.source}
                  selectedColor="#fff"
                />
              </div>
            );
          })}
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};

export const SidebarOptions = ({
  handlePrint,
  handleImageUpload,
  handleNavigateToMap,
  imageUploadRef,
  handleSave,
  setShowSuccessMessage,
}: SidebarOptionsProps) => {
  const { reportData } = useReport();

  return (
    <div>
      <Accordion type="single" collapsible className="w-full">
      <SidebarGroup title="Calles, intersecciones y glorietas" options={streetSigns} />
      <SidebarGroup title="Mobiliario Urbano" options={objectSings} />
      <SidebarGroup title="Vegetación" options={vegetalSings} />
        <SidebarGroup title="Señales de verticales" options={trafficSigns} />
        <SidebarGroup title="Señales de horizontales" options={trafficHSigns} />
        <SidebarGroup title="Otras señales" options={otherTrafficSigns} />
        <SidebarGroup title="Vehículos" options={carsSings} />
        <SidebarGroup title="Personas" options={peopleSings} />
        <SidebarGroup title="Animales" options={animalsSigns} />
        <SidebarGroup title="Líneas y formas" options={arrowSigns} />

      </Accordion>
      <button
        className="w-full relative overflow-hidden rounded-md px-3.5 py-2.5 bg-blue-500 text-white mb-2"
        onClick={handleNavigateToMap}
      >
        Buscar ubicación
      </button>
      <button
        className="w-full relative overflow-hidden rounded-md px-3.5 py-2.5 bg-blue-500 text-white mb-2"
      >
        Cargar Imagen
        <input ref={imageUploadRef} type="file" accept="image/*" onChange={handleImageUpload} className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer" />
      </button>
      <button
        className="w-full relative overflow-hidden rounded-md px-3.5 py-2.5 bg-blue-500 text-white mb-2"
      >
        Guardar
      </button>
      <button 
        className="w-full relative overflow-hidden rounded-md px-3.5 py-2.5 bg-blue-500 text-white mb-2"      >
        Cancelar
        </button>
      <button
        className="w-full relative overflow-hidden rounded-md px-3.5 py-2.5 bg-blue-500 text-white mb-2"
      >
        Nuevo croquis
      </button>
      <button
        className="w-full rounded-md px-3.5 py-2.5 bg-blue-500 text-white mb-2"
        onClick={handlePrint}
      >
        Imprimir
      </button>
      
      
      {reportData && (
        <>
          <AccidentDescription />
          <button
            className="w-full relative overflow-hidden rounded-md px-3.5 py-2.5 bg-blue-500 text-white mb-2"
            onClick={() => {
              handleSave();
              setShowSuccessMessage(true);
              setTimeout(() => setShowSuccessMessage(false), 3000);
            }}
          >
            Guardar
          </button>
        </>
      )}
    </div>
  );
};