import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export async function fetchWithAuth(url: string, options: RequestInit = {}) {
  const credentials = btoa(`${process.env.REACT_APP_API_USER}:${process.env.REACT_APP_API_PASSWORD}`);
  const headers = new Headers(options.headers || {});
  headers.set('Authorization', `Basic ${credentials}`);

  const response = await fetch(url, {
    ...options,
    headers,
  });

  return response;
}


