import { DragSourceMonitor, useDrag } from "react-dnd";
import "./element.css";
import { ElementProps } from "./types";
import { streetSigns, trafficSigns, carsSings, peopleSings,objectSings, arrowSigns, animalsSigns,vegetalSings,trafficHSigns,otherTrafficSigns } from "../../data";

export const Element = (props: ElementProps) => {
  const fileName = props.source.split("/").at(-1)?.split(".")[0] || "";

  const { top, left } = props;

  trafficSigns
    .concat(streetSigns)
    .concat(carsSings)
    .concat(peopleSings)
    .concat(objectSings)
    .concat(arrowSigns)
    .concat(animalsSigns)
    .concat(vegetalSings)
    .concat(trafficHSigns)
    .concat(otherTrafficSigns)
    .map((sign) => sign.source);

  const [{isDragging }, drag] = useDrag(
    
    () => ({
      type: props.source,
      item: { ...props },
      canDrag: true,
      collect: (monitor: DragSourceMonitor) => {
        return {
          isDragging: monitor.isDragging(),
          item: monitor.getItemType()
        };
      },
    }),
    []
  );
// console.log('item',item, isDragging)
  // const [, drop] = useDrop({
  //   accept: acceptedTypes,
  //   drop: (item, monitor) => {
  //     const delta = monitor.getDifferenceFromInitialOffset() as XYCoord;
  //     const newLeft = Math.round((left + delta.x) / 100) * 100;
  //     const newTop = Math.round((top + delta.y) / 100) * 100;
  //     moveItem(id, newLeft, newTop);
  //   },
  // });

  return (
    <div
      // ref={(node) => drag(drop(node))}
      ref={drag}
      className="Element-container"
      style={{
        position: "relative",
        left,
        top,
        opacity: isDragging ? 0.5 : 1,
        cursor: "move",
        transform: "rotate(0deg)"
      }}
    >
      <img src={props.icon} alt={fileName} />
    </div>
  );
};
